
export default {
  name: 'Default',
  head() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://aibuddha.in' + this.$route.path
        }
      ]
    }
  }
}
