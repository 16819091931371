export default async ({ app }, inject) => {
  if (process.client) {
    const gsap = await import("gsap");
    const ScrollTrigger = await import("gsap/ScrollTrigger");

    gsap.default.registerPlugin(ScrollTrigger.default);

    inject("gsap", gsap.default);
    inject("ScrollTrigger", ScrollTrigger.default);
  }
};
