
import { CONSTANTS } from "@/constants";
export default {
  name: "SiteNavbar",
  head() {
    return {
      htmlAttrs: {
        class: this.menuShown ? "has-modal-open" : "",
      },
    };
  },
  props: {
    showBanner: Boolean,
  },
  data() {
    return {
      menuShown: false,
      ProductsSubMenuHidden: true,
      PAGE_LINKS: CONSTANTS.PAGE_LINKS,
    };
  },
  methods: {
    openModal: function () {
      // const modal = this.$refs.myModal;
      // const modalClass = ["has-modal-open", "is-menu-open"];
      // if (this.menuShown) {
      //   modalClass.forEach((className) => {
      //     modal.classList.remove(className);
      //   });
      // } else {
      //   modalClass.forEach((className) => {
      //     modal.classList.add(className);
      //   });
      // }
    },
  },
};
