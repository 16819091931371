import { render, staticRenderFns } from "./default.vue?vue&type=template&id=51acaa4e"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SiteNavbar: require('/home/rohit/Documents/GitHub/aibuddha-website/components/SiteNavbar.vue').default,Footer: require('/home/rohit/Documents/GitHub/aibuddha-website/components/Footer.vue').default,CustomCursor: require('/home/rohit/Documents/GitHub/aibuddha-website/components/CustomCursor.vue').default})
