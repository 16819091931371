
    export default {
        mounted(){
            const cursor = this.$refs["c1"];
            const cursor2 = this.$refs["c2"];
            document.addEventListener("mousemove",function(e) {
                cursor.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
                cursor2.style.transform = `translate3d(${e.clientX}px, ${e.clientY}px, 0)`;
            });
            const anchorTags = document.querySelectorAll("a, button, input[type=submit], summary");
            anchorTags.forEach((anchor) => {
                anchor.addEventListener("mouseover", () => {
                    cursor.style.backgroundColor = "white";
                    cursor2.style.width = "90px";
                    cursor2.style.height = "90px";
                    cursor2.style.margin = "-45px";
                });
                anchor.addEventListener("mouseout", () => {
                    cursor.style.backgroundColor = "#815FF6"
                    cursor2.style.width = "50px";
                    cursor2.style.height = "50px";
                    cursor2.style.margin = "-25px";
                });
            });

        }
    }
